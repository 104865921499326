.create_wallet_page {
  padding-top: 50px;
  width: 80%;
  text-align: center;
}
.create_wallet_page .action_box {
  display: inline-block;
}
.create_wallet_page .action_box .left {
  align-items: center;
  display: flex;
}
.create_wallet_page .action_box .left .ant-btn {
  margin-left: 10px;
}
.create_wallet_page .wallet_list {
  width: 70%;
  padding-bottom: 50px;
  margin: 0 auto;
}
.create_wallet_page .wallet_list .wallet_item {
  display: flex;
  margin-bottom: 10px;
}
.create_wallet_page .wallet_list .wallet_item > * {
  margin-left: 10px;
}
.create_wallet_page .wallet_list .wallet_item > *:first-child {
  margin-left: 0;
  width: 50%;
}
