.page_balance_collection {
  width: 80%;
}
.page_balance_collection .box {
  display: flex;
}
.page_balance_collection .box .target_address {
  margin-right: 5px;
}
.page_balance_collection .box .target_address {
  flex: 1;
}
.page_balance_collection .box .top {
  flex: 1;
  position: relative;
}
.page_balance_collection .box .top .token_name {
  position: absolute;
  bottom: 100%;
  left: 3px;
  font-weight: 600;
}
.page_balance_collection .box .top .rpc {
  margin: 10px 0 10px;
}
.page_balance_collection .box .top .query {
  width: 100%;
}
.page_balance_collection .list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 150px;
}
.page_balance_collection .tip {
  color: #ff4d4f;
}
