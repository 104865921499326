.gas_info.warning_gas .ant-collapse-item {
  background: rgba(242, 151, 0, 0.883) !important;
}
.gas_info.alarm_gas .ant-collapse-item {
  background: #bf1920 !important;
}
.gas_info .ant-collapse-item {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
}
.gas_info .ant-collapse-item .ant-collapse-header {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border-top: 1px solid #d9d9d9;
}
.gas_info .ant-collapse-item .ant-collapse-header * {
  color: #231815;
  font-weight: 600;
}
.gas_info .ant-collapse-item .ant-collapse-header img {
  width: 18px;
  height: 18px;
}
.gas_info .ant-collapse-item .ant-collapse-header .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
}
.gas_info .ant-collapse-item .ant-collapse-header .header .gas {
  display: flex;
  align-items: center;
}
.gas_info .ant-collapse-item .ant-collapse-header .header .gas img {
  margin-right: 3px;
}
.gas_info .ant-collapse-item .ant-collapse-header .header .gas span {
  color: #5fce86;
  font-size: 19px;
}
.gas_info .ant-collapse-item .ant-collapse-header .header .gas span:last-child {
  font-size: 14px;
  color: #231815;
  position: relative;
  top: 1px;
}
.gas_info .ant-collapse-item .ant-collapse-header .header .block_price {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.gas_info .ant-collapse-item .ant-collapse-header .header .block_price .block,
.gas_info .ant-collapse-item .ant-collapse-header .header .block_price .price {
  display: flex;
  align-items: center;
}
.gas_info .ant-collapse-content-box {
  padding: 0 !important;
}
.gas_info .ant-collapse-content-box .detail p {
  padding: 0;
  margin: 0;
}
.gas_info .ant-collapse-content-box .detail .gas_block {
  margin: 0 10px 0;
  padding: 5px 0 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.gas_info .ant-collapse-content-box .detail .gas_block .txt {
  width: 30%;
}
.gas_info .ant-collapse-content-box .detail .gas_block .value {
  text-align: center;
  flex: 1;
}
.gas_info .ant-collapse-content-box .detail .gas_block .value p {
  white-space: nowrap;
}
.gas_info .ant-collapse-content-box .detail .gas_list .gas_block:nth-child(1) .txt {
  color: #4cea98;
}
.gas_info .ant-collapse-content-box .detail .gas_list .gas_block:nth-child(2) .txt {
  color: #4eea5a;
}
.gas_info .ant-collapse-content-box .detail .gas_list .gas_block:nth-child(3) .txt {
  color: #bcea58;
}
.gas_info .ant-collapse-content-box .detail .gas_list .gas_block:nth-child(4) .txt {
  color: #ffe00b;
}
.gas_info .ant-collapse-content-box .detail .gas_list .gas_block:nth-child(5) .txt {
  color: #eab05a;
}
