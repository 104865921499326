.page_rpc {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 0 !important;
}
.page_rpc .rpc_box {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.page_rpc .rpc_box .rpc_list .head {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
}
.page_rpc .rpc_box .rpc_list .provider_box {
  margin-bottom: 30px;
}
.page_rpc .tip {
  color: #ff4d4f;
  font-size: 16px;
  font-weight: 600;
}
