.page_proof {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page_proof .query {
  display: flex;
}
.page_proof .query .url,
.page_proof .query .value {
  flex: 1;
}
.page_proof .query .url {
  margin-right: 10px;
}
.page_proof .query .value {
  margin-left: 10px;
}
.page_proof > div {
  width: 100%;
}
.page_proof .request_info {
  display: flex;
  margin-bottom: 10px;
}
.page_proof .request_info button {
  margin-left: 10px;
}
.page_proof .res_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.page_proof h1 {
  font-size: 16px;
  font-weight: 600;
}
.page_proof .res_value {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 这里是超出几行省略 */
  cursor: pointer;
}
