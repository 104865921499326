.private_key_group_bos {
  display: flex;
  margin-top: 5px;
}
.private_key_group_bos .lists {
  flex: 1;
  display: flex;
}
.private_key_group_bos .sort_box .group_item.disabled {
  cursor: not-allowed;
}
.private_key_group_bos .sort_box,
.manage_private_key_group_modal .sort_box {
  flex: 1;
  margin-left: 5px;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
}
.private_key_group_bos .sort_box .group_item,
.manage_private_key_group_modal .sort_box .group_item {
  margin-top: 3px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  padding: 0 3px 0;
  margin-right: 5px;
  white-space: nowrap;
  user-select: none;
}
.private_key_group_bos .sort_box .group_item .close,
.manage_private_key_group_modal .sort_box .group_item .close {
  margin-left: 5px;
  cursor: pointer !important;
}
.private_key_group_bos .sort_box .group_item .close:hover,
.manage_private_key_group_modal .sort_box .group_item .close:hover {
  color: #ff4d4f;
}
.private_key_group_bos .sort_box .group_item .edit,
.manage_private_key_group_modal .sort_box .group_item .edit {
  margin-right: 5px;
  cursor: pointer !important;
}
.private_key_group_bos .sort_box .group_item .edit:hover,
.manage_private_key_group_modal .sort_box .group_item .edit:hover {
  color: #1890ff;
}
.private_key_group_bos .sort_box .group_item.seleted,
.manage_private_key_group_modal .sort_box .group_item.seleted {
  background-color: #1890ff;
  color: white;
}
.manage_private_key_group_modal .new_group_form {
  display: flex;
}
.manage_private_key_group_modal .new_group_form .name {
  margin-right: 10px;
  flex-direction: column;
  flex: 2;
  display: flex;
}
.manage_private_key_group_modal .new_group_form .name input {
  margin-bottom: 10px;
}
.manage_private_key_group_modal .new_group_form textarea {
  flex: 3;
}
.manage_private_key_group_modal .export_import {
  display: flex;
  float: right;
  margin: 25px 0 10px;
}
.manage_private_key_group_modal .export_import button {
  margin-left: 5px;
}
