.page_nft_distribution {
  width: 80%;
}
.page_nft_distribution .header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.page_nft_distribution .header .nft_info {
  flex: 1;
}
.page_nft_distribution .header .nft_info .top {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.page_nft_distribution .header .nft_info .top .ant-select {
  margin-right: 15px;
}
.page_nft_distribution .header .nft_info .top > input {
  flex: 1;
}
.page_nft_distribution .header .nft_info .top .rpc {
  flex: 1;
  max-width: 400px;
  margin-left: 15px;
}
.page_nft_distribution .header .nft_info .section {
  margin-top: 10px;
  display: flex;
  width: 100%;
}
.page_nft_distribution .header .nft_info .section .section_left,
.page_nft_distribution .header .nft_info .section .address_list {
  flex: 1;
}
.page_nft_distribution .header .nft_info .section .section_left {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.page_nft_distribution .header .nft_info .section .section_left .private_key,
.page_nft_distribution .header .nft_info .section .section_left .gas_info,
.page_nft_distribution .header .nft_info .section .section_left .query {
  width: 100%;
}
.page_nft_distribution .header .nft_info .section .section_left .query {
  margin: 12px 0 12px;
}
.page_nft_distribution .header .nft_info .section .section_left .query button {
  width: 100%;
}
.page_nft_distribution .header .nft_info .section .section_left .gas_info {
  display: flex;
}
.page_nft_distribution .header .nft_info .section .section_left .gas_info .priority_fee {
  flex: 2;
  padding-right: 20px;
}
.page_nft_distribution .header .nft_info .section .section_left .gas_info .max_fee {
  flex: 3;
}
.page_nft_distribution .header .nft_info .section .section_left .gas_info .priority_fee,
.page_nft_distribution .header .nft_info .section .section_left .gas_info .max_fee {
  display: flex;
  align-items: center;
}
.page_nft_distribution .header .nft_info .section .section_left .gas_info .priority_fee .key,
.page_nft_distribution .header .nft_info .section .section_left .gas_info .max_fee .key {
  font-weight: 600;
  margin-right: 10px;
}
.page_nft_distribution .header .nft_info .section .section_left .gas_info .priority_fee .ant-input-number,
.page_nft_distribution .header .nft_info .section .section_left .gas_info .max_fee .ant-input-number {
  flex: 1;
}
.page_nft_distribution .list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 150px;
}
.page_nft_distribution .list .header {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page_nft_distribution .list .header .root_address {
  font-weight: 600;
}
.page_nft_distribution .list .header .action {
  display: flex;
}
.page_nft_distribution .list .header .action .num_nft_per {
  align-items: center;
  display: flex;
  margin-right: 10px;
}
.page_nft_distribution .tip {
  color: #ff4d4f;
}
