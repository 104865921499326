.page {
  position: relative;
  padding-top: 100px;
}
.page_feedback {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 60%;
}
.page_feedback p {
  margin-top: 30px;
}
.page_feedback .submit {
  margin-top: 30px;
}
.page_feedback .tip {
  margin-top: 50px;
  color: #666;
}
