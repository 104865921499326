.page_filter {
  margin: 0 auto;
  padding-top: 50px !important;
  width: 80%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 150px;
}
.page_filter .rpc {
  float: right;
  width: 30%;
}
.page_filter .form_info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.page_filter .form_info .mnemonic {
  width: 100%;
  margin-top: 10px;
  display: flex;
}
.page_filter .form_info .mnemonic button {
  margin-left: 10px;
}
.page_filter .form_info .count_limit,
.page_filter .form_info .show_balance,
.page_filter .form_info .balance_limit {
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-right: 40px;
  font-weight: 600;
}
.page_filter .form_info .show_balance {
  margin-right: 0;
}
.page_filter .form_info .submit {
  margin-top: 10px;
  flex: 1;
  text-align: right;
}
.page_filter .table_list {
  width: 100%;
}
.page_filter .copy {
  position: relative;
  top: -44px;
}
.page_filter .copy button:first-child {
  margin-right: 10px;
}
.page_filter .copy input {
  width: 120px;
  margin-right: 10px;
}
