.data_mint_page {
  margin: 0 auto;
  padding-top: 50px !important;
  width: 80%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 150px;
}
.data_mint_page .header {
  width: 100%;
  display: flex;
}
.data_mint_page .header .rpc {
  display: inline-block;
  flex: 1;
}
.data_mint_page .header button {
  margin-right: 10px;
}
.data_mint_page .section {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
}
.data_mint_page .section .priority_fee,
.data_mint_page .section .max_fee,
.data_mint_page .section .price {
  margin-right: 30px;
}
.data_mint_page .section .data {
  align-items: flex-start;
}
.data_mint_page .section .data .input_data_value {
  flex: 1;
}
.data_mint_page .section .data .input_data_value .fun_params_box {
  border: 1px solid #ddd;
  padding: 4px 11px 4px;
}
.data_mint_page .section .data .input_data_value .fun_params_box .parse_box {
  margin-top: 2px;
  display: flex;
  position: relative;
}
.data_mint_page .section .data .input_data_value .fun_params_box .parse_box .ant-input:first-child {
  margin-right: 10px;
}
.data_mint_page .section .data .input_data_value .fun_params_box .parse_box .ant-input {
  flex: 2;
}
.data_mint_page .section .data .input_data_value .fun_params_box .parse_box .ant-input:first-child {
  flex: 1;
}
.data_mint_page .section .address {
  position: relative;
}
.data_mint_page .section .address .selling {
  position: absolute;
  right: 0;
  bottom: 110%;
}
.data_mint_page .section .tx,
.data_mint_page .section .data,
.data_mint_page .section .address {
  flex: 1;
}
.data_mint_page .section > .gas_limit .ant-input-number {
  width: 130px;
}
.data_mint_page .section .now_max,
.data_mint_page .section .now_xf {
  margin-left: 30px;
}
.data_mint_page .section > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.data_mint_page .section > div .key {
  white-space: nowrap;
  margin-right: 10px;
  font-weight: 600;
}
.data_mint_page .list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.data_mint_page .list .head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.data_mint_page .list .head .force_send {
  align-items: center;
  display: flex;
}
.data_mint_page .list .head .force_send .key {
  font-weight: 600;
  margin-right: 10px;
}
.data_mint_page .list .head .force_send .re_try_tip {
  color: #ff4d4f;
  margin-left: 10px;
}
.data_mint_page .list .head .action > button {
  margin-left: 10px;
}
.data_mint_page .list .hash_cell,
.data_mint_page .list .status_cell {
  padding: 0 !important;
}
.data_mint_page .list .hash_cell .status,
.data_mint_page .list .status_cell .status,
.data_mint_page .list .hash_cell .hash,
.data_mint_page .list .status_cell .hash {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px;
}
.data_mint_page .list .hash_cell .status:last-child,
.data_mint_page .list .status_cell .status:last-child,
.data_mint_page .list .hash_cell .hash:last-child,
.data_mint_page .list .status_cell .hash:last-child {
  border: none;
}
.data_mint_page .list .count_header {
  display: flex;
  align-items: center;
}
.data_mint_page .list .count_header .ant-input-number {
  margin-left: 8px;
}
.data_mint_page .make_order {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data_mint_page .make_order .total_amount {
  font-weight: 600;
}
.up_speed > div {
  margin-left: 20px;
}
