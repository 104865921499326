.transfer_tokens_page {
  padding-top: 50px;
  width: 80%;
  text-align: center;
}
.step1 .top {
  justify-content: space-between;
  display: flex;
  align-items: center;
  position: relative;
}
.step1 .top .token_address_input {
  flex: 1;
}
.step1 .top .rpc {
  flex: 1;
  max-width: 400px;
  margin-left: 15px;
}
.step1 .top .token_name {
  position: absolute;
  bottom: 100%;
  left: 3px;
  font-weight: 600;
}
.step1 .input_box .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.step1 .input_box .header .left {
  display: flex;
  justify-content: left;
  align-items: center;
}
.step1 .input_box .header .left h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 10px;
}
.step1 .input_box .header .left .anticon {
  cursor: pointer;
}
.step1 .input_box .header .right .ant-btn {
  margin-left: 4px;
  position: relative;
  top: -1px;
}
.step1 .input_box .content .textarea {
  text-align: left;
}
.step1 .input_box .content .bottom_action {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.step1 .input_box .content .bottom_action p {
  margin-bottom: 0;
}
.step2 {
  text-align: left;
}
.step2 .mode {
  display: flex;
}
.step2 .mode .suport_chain {
  margin-left: 10px;
  font-weight: 600;
  color: #b91c1c;
}
.step2 h3 {
  font-size: 16px;
  font-weight: 600;
}
.step2 .speed .gas {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.step2 .speed .gas .rpc {
  flex: 1;
}
.step2 .speed .gas .max_fee {
  margin-right: 20px;
}
.step2 .speed .gas .max_fee,
.step2 .speed .gas .priority_fee {
  display: flex;
  align-items: center;
}
.step2 .speed .gas .max_fee > div,
.step2 .speed .gas .priority_fee > div {
  margin-right: 5px;
}
.step2 .list {
  padding-top: 30px;
}
.step2 .list .del_action {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.step2 .list .bottom_action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.step2 .list .bottom_action .ant-btn {
  margin-left: 10px;
}
.step2 .use_designated_wallet {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.step2 .use_designated_wallet .left {
  display: flex;
  flex: 1;
  align-items: center;
}
.step2 .use_designated_wallet .left .ant-switch {
  margin: 0 10px;
}
.step2 .use_designated_wallet .left input {
  width: 300px;
  margin-right: 10px;
}
.step2 .use_designated_wallet .right button {
  margin-left: 10px;
}
.step3 .approve {
  display: flex;
  align-items: center;
}
.step3 .approve div {
  margin-right: 10px;
}
.step3 .steps {
  margin-top: 50px;
  padding: 0 20px 0;
}
.del {
  color: #b91c1c;
  cursor: pointer;
}
.transfer_mode {
  margin-top: 50px;
}
