.main.withdraw_page {
  width: 80%;
  margin: 0 auto;
}
.main.withdraw_page .modo {
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.main.withdraw_page .body {
  display: flex;
}
.main.withdraw_page .body .input_box {
  flex: 1;
  position: relative;
}
.main.withdraw_page .body .input_box p {
  z-index: 99;
  position: absolute;
  right: 5px;
  bottom: 0px;
}
.main.withdraw_page .body .withdraw_info {
  margin-left: 7.5px;
  flex: 1;
}
.main.withdraw_page .body .withdraw_info .delay,
.main.withdraw_page .body .withdraw_info .amount {
  display: flex;
  align-items: center;
  margin-bottom: 7.5px;
}
.main.withdraw_page .body .withdraw_info .delay .ant-input-number,
.main.withdraw_page .body .withdraw_info .amount .ant-input-number {
  flex: 1;
  margin-right: 7.5px;
}
.main.withdraw_page .body .withdraw_info .time_mode2 {
  flex: 1;
  display: flex;
  align-items: center;
}
.main.withdraw_page .body .withdraw_info .time_mode2 .ant-input-number {
  flex: 1;
  margin-right: 7.5px;
}
.main.withdraw_page .body .withdraw_info .select {
  margin-top: 7.5px;
  display: flex;
}
.main.withdraw_page .body .withdraw_info .select .ant-select {
  flex: 1;
}
.main.withdraw_page .body .withdraw_info .tip {
  font-size: 10px;
  color: red;
}
.main.withdraw_page .body .withdraw_info .ant-input {
  margin-top: 7.5px;
}
.main.withdraw_page .body .withdraw_info .submit {
  width: 100%;
  margin-top: 7.5px;
}
.main.withdraw_page .list {
  padding-bottom: 100px;
}
.main.withdraw_page .list .action {
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
}
