.nft_page {
  margin: 0 auto;
  padding-top: 50px !important;
  width: 80%;
  flex-direction: column;
  align-items: flex-start;
}
.nft_page .header {
  width: 100%;
  display: flex;
}
.nft_page .header .rpc {
  margin-left: 10px;
  display: inline-block;
  flex: 1;
}
.nft_page .section {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
}
.nft_page .section .priority_fee,
.nft_page .section .max_fee,
.nft_page .section .price,
.nft_page .section .mint_fun,
.nft_page .section .gas_limit {
  margin-right: 20px;
}
.nft_page .section .tx,
.nft_page .section .data,
.nft_page .section .address {
  flex: 1;
}
.nft_page .section > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.nft_page .section > div .key {
  white-space: nowrap;
  margin-right: 10px;
  font-weight: 600;
}
.nft_page .section.parse_section {
  display: flex;
  flex-direction: column;
}
.nft_page .section .parse_box {
  margin-top: 2px;
  display: flex;
  position: relative;
}
.nft_page .section .parse_box .ant-input:first-child {
  margin-right: 10px;
}
.nft_page .section .parse_box .ant-input {
  margin-right: 50px;
  flex: 2;
}
.nft_page .section .parse_box .ant-input:first-child {
  flex: 1;
}
.nft_page .section .parse_box .del_btn {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 5px;
  background-color: #eee;
  cursor: pointer;
}
.nft_page .section .parse_box .del_btn:hover {
  background-color: #ddd;
}
.nft_page .read_params {
  display: flex;
  margin-bottom: 10px;
}
.nft_page .read_params .target_address,
.nft_page .read_params .handle_res_script {
  flex: 1;
}
.nft_page .read_params .target_address {
  margin-right: 10px;
}
.nft_page .read_params .handle_res_script .output_type {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nft_page .read_params .handle_res_script .output_type .ant-select {
  margin: 0 10px;
  flex: 1;
}
.nft_page .read_params .handle_res_script button {
  margin-top: 10px;
  width: 100%;
}
.nft_page .list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 150px;
}
.nft_page .list .action {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nft_page .list .action .start_time {
  display: flex;
  align-items: center;
}
.nft_page .list .action .start_time .time_to_start {
  display: flex;
  align-items: center;
}
.nft_page .list .action .start_time .key {
  white-space: nowrap;
  font-weight: 600;
}
.nft_page .list .action .start_time .ant-picker {
  margin-left: 10px;
}
.nft_page .list .action .re_try_count {
  margin-left: 10px;
  color: #ff4d4f;
}
.nft_page .list .action button {
  margin-left: 10px;
}
.nft_page .list .hash_cell,
.nft_page .list .status_cell {
  padding: 0 !important;
}
.nft_page .list .hash_cell .status,
.nft_page .list .status_cell .status,
.nft_page .list .hash_cell .hash,
.nft_page .list .status_cell .hash {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px;
}
.nft_page .list .hash_cell .status:last-child,
.nft_page .list .status_cell .status:last-child,
.nft_page .list .hash_cell .hash:last-child,
.nft_page .list .status_cell .hash:last-child {
  border: none;
}
.up_speed > div {
  margin-left: 20px;
}
