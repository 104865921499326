/** main App start **/
.left-menu {
  height: 100%;
  border-right: 0;
}
.site-layout-main {
  padding: 24px;
  min-height: calc(100vh - 64px) !important;
}
.white-background {
  background: #fff;
}
.ant-layout-header {
  padding: 0 20px !important;
}
.header_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_box .logo {
  display: flex;
  cursor: pointer;
  height: 40px;
}
.header_box .logo img {
  margin-right: 5px;
}
.header_box .ant-menu-root {
  flex: 1;
  display: flex;
  justify-content: center;
}
.header_box .right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_box .right .chain {
  color: #fff;
  font-weight: 800;
  margin-right: 10px;
  white-space: nowrap;
}
/** main App end **/
.page {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_404 {
  margin-top: 200px;
}
.gas_eth_price {
  position: fixed;
  bottom: -1px;
  left: -1px;
  width: 200px;
  z-index: 9999;
}
