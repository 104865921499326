.provider_box {
  width: 100%;
  position: relative;
  display: flex;
}
.provider_box > input {
  margin-right: 10px;
}
.provider_box .rpc_res_info {
  position: absolute;
  right: -8px;
  bottom: 110%;
}
