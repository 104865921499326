.page_nft_collection {
  width: 80%;
}
.page_nft_collection .header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.page_nft_collection .header .nft_info {
  flex: 1;
}
.page_nft_collection .header .nft_info .top {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.page_nft_collection .header .nft_info .top .ant-select {
  margin-right: 15px;
}
.page_nft_collection .header .nft_info .top > input {
  flex: 1;
}
.page_nft_collection .header .nft_info .top .rpc {
  flex: 1;
  max-width: 400px;
  margin-left: 15px;
}
.page_nft_collection .header .nft_info .section {
  margin-top: 10px;
  display: flex;
  width: 100%;
}
.page_nft_collection .header .nft_info .section .priority_fee,
.page_nft_collection .header .nft_info .section .max_fee {
  margin-right: 20px;
}
.page_nft_collection .header .nft_info .section .target_address {
  flex: 1;
}
.page_nft_collection .header .nft_info .section > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page_nft_collection .header .nft_info .section > div .key {
  white-space: nowrap;
  margin-right: 10px;
  font-weight: 600;
}
.page_nft_collection .list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 150px;
}
.page_nft_collection .list .action {
  margin-bottom: 10px;
  float: right;
}
.page_nft_collection .list .hash_cell,
.page_nft_collection .list .token_cell,
.page_nft_collection .list .status_cell {
  padding: 0 !important;
}
.page_nft_collection .list .hash_cell .status,
.page_nft_collection .list .token_cell .status,
.page_nft_collection .list .status_cell .status,
.page_nft_collection .list .hash_cell .token,
.page_nft_collection .list .token_cell .token,
.page_nft_collection .list .status_cell .token,
.page_nft_collection .list .hash_cell .hash,
.page_nft_collection .list .token_cell .hash,
.page_nft_collection .list .status_cell .hash {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px;
}
.page_nft_collection .list .hash_cell .status:last-child,
.page_nft_collection .list .token_cell .status:last-child,
.page_nft_collection .list .status_cell .status:last-child,
.page_nft_collection .list .hash_cell .token:last-child,
.page_nft_collection .list .token_cell .token:last-child,
.page_nft_collection .list .status_cell .token:last-child,
.page_nft_collection .list .hash_cell .hash:last-child,
.page_nft_collection .list .token_cell .hash:last-child,
.page_nft_collection .list .status_cell .hash:last-child {
  border: none;
}
.page_nft_collection .tip {
  color: #ff4d4f;
}
