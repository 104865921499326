.page_personal_center {
  width: 80%;
}
.page_personal_center .section {
  margin-top: 20px;
}
.page_personal_center .section .title_box {
  justify-content: space-between;
  display: flex;
  align-items: flex-end;
}
.page_personal_center .section .title_box .title {
  font-weight: 800;
  font-size: 18px;
}
.page_personal_center .section .title_box p {
  margin: 0;
}
.page_personal_center .section.children_address .address_box {
  text-align: center;
}
.page_personal_center .section.children_address .address_box input {
  margin-bottom: 10px;
}
.page_personal_center .section.children_address button {
  float: right;
}
