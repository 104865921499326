.private_key_box {
  display: flex;
  position: relative;
  width: 100%;
}
.private_key_box .center_box {
  flex: 1;
}
.private_key_box .ant-upload {
  height: 100%;
}
.private_key_box .upload {
  border: 1px dotted #c3c3c3;
  border-radius: 2px;
  height: 100%;
  width: 125px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #c3c3c3;
}
.private_key_box .upload.disable {
  cursor: no-drop;
}
.private_key_box .upload > p {
  margin-top: 15px;
  font-size: 12px;
}
.private_key_box .upload:hover {
  border-color: dodgerblue;
}
.private_key_box .payable_mount {
  width: 100%;
}
.private_key_box .private_action {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.private_key_box .private_action .ant-btn {
  margin-bottom: 5px;
}
.private_key_box .balance_verify_ing {
  position: absolute;
  right: -25px;
  top: 6px;
}
