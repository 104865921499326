.add_account_page {
  width: 80%;
}
.add_account_page .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.add_account_page .action {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
