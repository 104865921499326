.page {
  position: relative;
  padding-top: 100px;
}
.page_become_vip {
  width: 60%;
}
.page_become_vip .header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 10px 0;
}
.page_become_vip .header button {
  margin-right: 20px;
}
.page_become_vip .body {
  padding: 0 10px 0;
}
