.page_home {
  padding: 0 100px;
  text-align: left;
  justify-content: flex-start;
}
.page_home a {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  display: block;
}
